<template>
  <div class="detail-approval-btn">
    <div class="btn-wrap" @click="handleApproval('reject')">
      <van-icon name="revoke" />
      <span>驳回申请</span>
    </div>
    <div class="btn-wrap" @click="handleApproval('referral')">
      <van-icon name="share-o" />
      <span>转发审批</span>
    </div>
    <div class="btn-wrap" @click="handleApproval('pass')">
      <van-icon name="passed" />
      <span>审批通过</span>
    </div>
    <approval-dialog ref="approvalRef" :approvalType="approvalType" :billNo="billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant';
import ApprovalDialog from '@/components/business/approvalDialog/index.vue';

Vue.use(Icon);
export default {
  name: 'DetailApprovalBtn',
  components:{
    ApprovalDialog,
  },
  props: {
    billNo: {
      type: String,
      require: true,
    }
  },
  data(){
    return {
      approvalType: '',
      isShowDialog: false,
    }
  },
  methods: {
    handleApproval(type){
      this.approvalType = type;
      this.$refs.approvalRef.changeDialogShow(true);
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-approval-btn{
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  border-top: 1px solid #F6F6F6;
  .btn-wrap{
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    &:nth-child(2){
      border-left: 1px solid #F6F6F6;
      border-right: 1px solid #F6F6F6;
    }
  }
}
</style>